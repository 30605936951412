import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";
import WhyChooseUs from "../Home/WhyChooseUs";
import Faq from "../Home/Faq";

// import Testimonials from "../Home/Testimonials";
// import GetInTouch from "../Home/GetInTouch";
// import WhyChooseUs from "../Home/WhyChooseUs";

const Pricing = () => {
  useEffect(() => {
    document.title = "Pricing - RupePro";
  }, []);

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="white_header" />

        {/* Breadcrumb */}
        <div className="rts-breadcrumb-area body-bg-2">
          <div className="container">
            <div className="breadcrumb-inner">
              <div className="row align-items-center">
                <div className="col-lg-6 order-change">
                  <div className="breadcrumb-content">
                    <h1 className="heading-title" style={{ maxWidth: 494 }}>
                      Pricing
                    </h1>
                    <p className="desc">
                      Transparent and Competitive Pricing Tailored to Your Needs
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="breadcrumb-image-area">
                    <img
                      src="assets/images/banner/breadcrumb-05.webp"
                      width={515}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="breadcrumb-shape-area">
            <img src="assets/images/banner/breadcrumb-shape.svg" alt="" />
          </div>
        </div>
        {/* Breadcrumb END */}
        {/* About Us Start */}
        <section className="rts-about-area">
          <div className="container">
            <>
              {/* PRICING PLAN START */}
              <div className="rts-pricing-plan section__padding">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="rts-section text-center">
                      <h2 className="rts-section__title">Pricing</h2>
                      <p className="rts-section__description w-450 ">
                        Transparent and Competitive Pricing Tailored to Your
                        Needs
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    {/* PRICING PLAN */}
                    <div className="tab__content1 open" id="monthly">
                      <div className="row g-30 monthly">
                        {/* single card */}
                        <div className="col-xl-4 col-lg-6 col-md-6">
                          <div className="card-plan">
                            <div className="card-plan__package">
                              <h4 className="package__name">Retailer</h4>
                            </div>
                            <p className="card-plan__desc"></p>
                            <h5 className="card-plan__price">₹199</h5>
                            <div className="card-plan__cartbtn">
                              <a href="https://app.rupepro.in/signup">
                                Register Now
                              </a>
                            </div>
                            <div className="card-plan__feature">
                              <ul className="card-plan__feature--list">
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" />
                                    Mobile and DTH Recharge
                                  </span>
                                </li>
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" />
                                    Google Play Recharge
                                  </span>
                                </li>
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" />{" "}
                                    Utility Bill Payments
                                  </span>
                                </li>
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" />
                                    Realtime Commission
                                  </span>
                                </li>
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" />
                                    Instant Wallet Topup
                                  </span>
                                </li>
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" /> 24/7
                                    Support
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* single card end */}
                        {/* single card */}
                        <div className="col-xl-4 col-lg-6 col-md-6">
                          <div className="card-plan">
                            <div className="card-plan__package">
                              <h4 className="package__name">Distributor</h4>
                            </div>
                            <p className="card-plan__desc"></p>
                            <h5 className="card-plan__price">₹399</h5>
                            <div className="card-plan__cartbtn">
                              <a href="https://app.rupepro.in/signup">
                                Register Now
                              </a>
                            </div>
                            <div className="card-plan__feature">
                              <ul className="card-plan__feature--list">
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" />
                                    Add Unlimited Retailers
                                  </span>
                                </li>
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" />
                                    Mobile and DTH Recharge
                                  </span>
                                </li>
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" />
                                    Google Play Recharge
                                  </span>
                                </li>
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" />{" "}
                                    Utility Bill Payments
                                  </span>
                                </li>
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" />
                                    Realtime Commission
                                  </span>
                                </li>
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" />
                                    Instant Wallet Topup
                                  </span>
                                </li>
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" /> 24/7
                                    Support
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* single card end */}
                        {/* single card */}
                        <div className="col-xl-4 col-lg-6 col-md-6">
                          <div className="card-plan">
                            <div className="card-plan__package">
                              <h4 className="package__name">
                                Master Distributor
                              </h4>
                            </div>
                            <p className="card-plan__desc"></p>
                            <h5 className="card-plan__price">₹999</h5>
                            <div className="card-plan__cartbtn">
                              <a href="https://app.rupepro.in/signup">
                                Register Now
                              </a>
                            </div>
                            <div className="card-plan__feature">
                              <ul className="card-plan__feature--list">
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" />
                                    Add Unlimited Retailers
                                  </span>
                                </li>
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" />
                                    Add Unlimited Distributors
                                  </span>
                                </li>
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" />
                                    Mobile and DTH Recharge
                                  </span>
                                </li>
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" />
                                    Google Play Recharge
                                  </span>
                                </li>
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" />{" "}
                                    Utility Bill Payments
                                  </span>
                                </li>
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" />
                                    Realtime Commission
                                  </span>
                                </li>
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" />
                                    Instant Wallet Topup
                                  </span>
                                </li>
                                <li className="card-plan__feature--list-item">
                                  <span className="text">
                                    <i className="fa-regular fa-check" /> 24/7
                                    Support
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* single card end */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </section>
        {/* About Us END */}

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Pricing;
